import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '#const';

export const baseApi = createApi({
  reducerPath: 'baseAPI',
  tagTypes: [
    'MedicalDocuments',
    'MedicalDocumentsProcessing',
    'MedicalDocumentsReporting',
    // 'LaboratoryTests',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { userToken } = getState().auth;
      if (userToken) {
        // prevent override Authorization header, that allows to use `{ Authorization: null }` on purpose to skip the authorization
        if (!headers.has('Authorization')) {
          headers.set('Authorization', `Bearer ${userToken}`);
        }
      }
      return headers;
    },
  }),

  endpoints: () => ({}),
});
