export const Routes = {
  Home: { path: '/' },
  Login: { path: '/auth/login' },
  Register: { path: '/auth/register' },
  ChangePassword: { path: '/auth/change-password' },
  ForgotPassword: { path: '/auth/forgot-password' },
  ForgotPasswordSet: { path: '/auth/forgot-password/:uid/:token' },
  EmailVerification: { path: '/auth/email-verification/:token' },
  ResendEmailVerification: { path: '/auth/resend-email-verification' },
  ServerError: { path: '/server-error' },
  Profile: { path: '/profile' },
  ProfileEdit: { path: '/profile/edit' },
  Dashboard: { path: '/dashboard' },
  Account: { path: '/account' },
  Contact: { path: '/contact' },
  AccountSubscribe: { path: '/account/subscribe' },
  MedicalDocumentsList: { path: '/medical-documents' },
  MedicalDocumentCreate: { path: '/medical-documents/create' },
  MedicalDocument: { path: '/medical-documents/:id' },
  MedicalDocumentEdit: { path: '/medical-documents/:id/edit' },
  HealthReportsList: { path: '/health-reports' },
  HealthReportCreate: { path: '/health-reports/create' },
  HealthReport: { path: '/health-reports/:id' },
  HealthDataSurvey: { path: '/health-data-survey' },
  Disclaimers: { path: '/Disclaimers' },
  Privacy: { path: '/Privacy' },
  Terms: { path: '/terms' },
  Presentation: { path: '/presentation' },
  // LaboratoryTestsList: { path: '/laboratory-tests' },
};
