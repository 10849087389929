import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function Headers() {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Helmet>
      <html lang={lang || 'en'} />
      <title>{t('headers.title')}</title>
      <meta name='description' content={t('headers.description')} />
      <link rel='canonical' href={`https://www.medify.me/${lang || 'pl'}`} />
      <link rel='alternate' hrefLang='en' href='https://www.medify.me/en' />
      <link rel='alternate' hrefLang='pl' href='https://www.medify.me/pl' />
    </Helmet>
  );
}

export default Headers;
